import * as React from "react";
import { Link } from "gatsby";
import { graphql } from "gatsby";
import HeaderMenu from "../components/HeaderMenu/HeaderMenu";
import { withLayout, LayoutProps, menuItems } from "../components/Layout";
import Plyr from "plyr/dist/plyr.js";
import { ParallaxBanner } from "react-scroll-parallax";
import ReactMarkdown from "react-markdown/with-html";

interface IndexPageProps extends LayoutProps {
  data: {
    text: any;
  };
}

class VideoPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.videoRef = React.createRef();
  }

  render() {
    return (
      <video
        className="w-90 center"
        poster="https://www.klangpiraten.com/videos/klangpiraten_trailer_thumb.jpg"
        id="player"
        ref={this.videoRef}
        playsInline controls>
      <source src="https://www.klangpiraten.com/videos/klangpiraten_trailer_ydl.mp4" type="video/mp4" />
    </video>
    );
  }

  componentDidMount() {
    this.initPlyr(this.videoRef.current);
  }

  initPlyr(element) {
    if (element) {
      this.player = new Plyr(element, {
        blankVideo: "/blank.mp4",
        iconUrl: "/plyr.svg",
        settings: ["quality"],
        title: "Klangpiraten Trailer",
      });
    }
  }
}

const IndexPage = (props: IndexPageProps) => {
  const text = Object.fromEntries(props.data.text.edges.map((e) => [e.node.key, e.node.text]));
  return (
  <div>
    {/* Master head */}
      <HeaderMenu
        Link={Link} pathname={props.location.pathname} items={menuItems} inverted
      />

    <ParallaxBanner
      className="w-100 tc"
      layers={[
        {
          amount: 0.2,
          image: "kuerbispiraten_sd.jpg",
        },
        {
          amount: 0.0,
          children: (
      <div className="vh-25 vh-50-ns w-100 flex flex-column justify-between items-center">
        <div className="">
          <h1 className="dn">
            {text["home:title"]}
          </h1>
          <img src="kp_schriftzug.png" className="w5-ns w4-m w4 mt3" alt="Klangpiraten Logo" />
          <h2 className="black mt3-l mb0 mt0 f5 f4-m f3-ns" style={{textShadow: "rgba(255,255,255,0.8) 0 0 8px"}}>
            {text["home:subtitle"]}
          </h2>
        </div>
        <div className="mt5 mt0-ns flex flex-cloumn flex-justify-between">
          <a href="/music" className="db pa2 ph3 br3 ba f4 white bg-black o-80 ma3">Anhören</a>
          <a href="/page/contact" className="db pa2 ph3 br3 ba f4 white bg-black o-80 ma3">Booking</a>
        </div>
      </div>
          ),
        },
      ]}
      style={{
        color: "lime",
        height: "50vh",
      }}
    >
    </ParallaxBanner>

      <div className="pa4">
        <ReactMarkdown
          source={props.data.mainContent.Content}
          escapeHtml={false}
        />
      </div>

      <div className="pa4 flex">
        <VideoPlayer />
      </div>

        <div className="cf">
          <div className="fl w-100 w-50-ns pa4">
            <ReactMarkdown
              source={props.data.mainContent_left.Content}
              escapeHtml={false}
            />
          </div>
          <div className="fl w-100 w-50-ns pa4">
            <ReactMarkdown
              source={props.data.mainContent_right.Content}
              escapeHtml={false}
            />
          </div>
        </div>

  </div>
  );
};

export default withLayout(IndexPage);

export const pageQuery = graphql`
  query {
    text: allStrapiText {
      edges {
        node {
          key
          text
        }
      }
    }
    mainContent: strapiPage(Slug: {eq: "__home:top"}) {
      Content
    }
    mainContent_left: strapiPage(Slug: {eq: "__home:left"}) {
      Content
    }
    mainContent_right: strapiPage(Slug: {eq: "__home:right"}) {
      Content
    }
  }
`;
